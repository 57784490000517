const BBoxQuote = () => {
  return (
    <svg
      width="199"
      height="32"
      viewBox="0 0 199 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0H199V32.3498H0V0Z" fill="black" />
    </svg>
  );
};

export default BBoxQuote;
